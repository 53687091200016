<template>
	<v-data-table
		:headers="headers"
		:items="this.$store.state.cards"
		sort-by="id"
		item-key="id"
		class="elevation-1 border"
		:search="search"
		:loading="loaded"
		loading-text="Loading... Please wait">
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Manage Cards
				</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>

				<!-- Start Edit/New Dialogue -->
				<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn :fab="$vuetify.breakpoint.mdAndDown" :disabled="loaded" small color="primary" dark v-bind="attrs" v-on="on">
								<v-icon class="mx-2">mdi-credit-card-plus</v-icon> <span v-if="!$vuetify.breakpoint.mdAndDown"> Add New Card </span>
							</v-btn>
						</template>
					

					<v-card class="border">
						<v-card-title>
							<span class="text-h5">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-form ref="form">
									<v-row>
										<v-col cols="12" sm="12" md="12">
											<v-text-field type="number" @keypress="filter()" v-model="editedItem.id" label="Shoppy Id*" :rules="[rules.required, rules.min]">
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="12" md="12">
											<v-text-field type="number" @keypress="filter()" v-model="editedItem.barcode" label="Barcode*" :rules="[rules.required, rules.min]">
											</v-text-field>
										</v-col>
										<!-- <v-col cols="12" sm="12" md="12">
											<v-select
												v-model="editedItem.status"
												:items="['New', 'Asociated', 'Invalidated']"
												label="Status*"
												:rules="[rules.required, rules.check]"
											></v-select>
										</v-col> -->
										<v-col cols="12" sm="12" md="12">
											<v-text-field
												v-model="editedItem.name"
												label="Card Group Name*"
												:rules="[rules.required, rules.min]"
												>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="12" md="12">
											<v-row align="center">
												<v-checkbox
													v-model="affiliateStatus.calzedonia"
													hide-details
													class="shrink mr-2 mt-0">
												</v-checkbox>
												Calzedonia &nbsp;
												<v-text-field
													type="number"
													:min="1"
													:max="100"
													:disabled="!affiliateStatus.calzedonia"
													label="Percentage (%)"
													v-model="affiliateStatus.calzedoniaPercentage"
													@change="resetValue(0,affiliateStatus.calzedoniaPercentage)"
												></v-text-field>
											</v-row>

											<v-row align="center">
												<v-checkbox
													v-model="affiliateStatus.tezenis"
													hide-details
													class="shrink mr-2 mt-0"
												></v-checkbox>
												Tezenis &nbsp;
												<v-text-field
													type="number"
													:min="1"
													:max="100"
													:disabled="!affiliateStatus.tezenis"
													label="Percentage (%)"
													v-model="affiliateStatus.tezenisPercentage"
													@change="resetValue(1,affiliateStatus.tezenisPercentage)"
												></v-text-field>
											</v-row>

											<v-row align="center">
												<v-checkbox
													v-model="affiliateStatus.signorvino"
													hide-details
													class="shrink mr-2 mt-0"
												></v-checkbox>
												Signor Vino &nbsp;
												<v-text-field
													:min="1"
													:max="100"
													type="number"
													:disabled="!affiliateStatus.signorvino"
													label="Percentage (%)"
													v-model="affiliateStatus.signorvinoPercentage"
													@change="resetValue(2,affiliateStatus.signorvinoPercentage)"
												></v-text-field>
											</v-row>

											<v-row align="center">
												<v-checkbox
													v-model="affiliateStatus.ateliereme"
													hide-details
													class="shrink mr-2 mt-0"
												></v-checkbox>
												Atelier Eme &nbsp;
												<v-text-field
													type="number"
													:min="1"
													:max="100"
													:disabled="!affiliateStatus.ateliereme"
													label="Percentage (%)"
													v-model="affiliateStatus.atelieremePercentage"
													@change="resetValue(3,affiliateStatus.atelieremePercentage)"
												></v-text-field>
											</v-row>

											<v-row align="center">
												<v-checkbox
													v-model="affiliateStatus.intimissimi"
													hide-details
													class="shrink mr-2 mt-0"
												></v-checkbox>
												Intimissimi &nbsp;
												<v-text-field
													type="number"
													:min="1"
													:max="100"
													:disabled="!affiliateStatus.intimissimi"
													label="Percentage (%)"
													v-model="affiliateStatus.intimissimiPercentage"
													@change="resetValue(4,affiliateStatus.intimissimiPercentage)"
												></v-text-field>
											</v-row>

											<v-row align="center">
												<v-checkbox
													v-model="affiliateStatus.falconeri"
													hide-details
													class="shrink mr-2 mt-0"
												></v-checkbox>
												Falconeri &nbsp;
												<v-text-field
													type="number"
													:min="1"
													:max="100"
													:disabled="!affiliateStatus.falconeri"
													label="Percentage (%)"
													v-model="affiliateStatus.falconeriPercentage"
													@change="resetValue(5,affiliateStatus.falconeriPercentage)"
												></v-text-field>
											</v-row>
										</v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="close()"> Cancel </v-btn>
							<v-btn
								color="primary"
								text
								@click="
								createCard();">
									Save
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<!-- End Edit/New Dialogue -->

				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title class="text-h5"
							>Are you sure you want to delete this item?
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">
								Cancel
							</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">
								OK
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Start Error Dialog -->
				<v-dialog v-model="errorDialog" max-width="500px">
					<v-card>
						<v-alert
						:type="type"
						prominent
						border="left"
						dismissable
						close-text="Close Alert"
						class="ma-0">
						<v-row align="center">
							<v-col class="grow">
								{{errorMessage}}
							</v-col>
							<v-col class="shrink">
								<!-- <v-btn>Take action</v-btn> -->
								<v-btn color="white" text @click="errorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
							</v-col>
						</v-row>
						</v-alert>
					</v-card>
				</v-dialog>
				<!-- End Error Dialog -->

				<v-dialog v-model="dialogUpload" max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn :fab="$vuetify.breakpoint.mdAndDown" :disabled="loaded" class="mx-2" small color="primary" dark v-bind="attrs" v-on="on">
							<v-icon class="mx-2">mdi-cloud-upload</v-icon><span v-if="!$vuetify.breakpoint.mdAndDown"> Upload CSV File </span>
						</v-btn>
					</template>

					<v-card>
						<v-card-title>
							<span class="text-h5">Upload Cards</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-row>
										<v-form ref="form">
									<v-col cols="12" sm="6" md="4">
										<!-- <v-file-input
										label="File input"
										outlined
										dense
										multiple
										type="file" id="file" ref="myFiles" @change="getBase64()"
										></v-file-input> -->
											<input
												class="d-flex justify-start"
												type="file"
												id="file"
												ref="myFiles"
												accept=".csv"
												@change="getBase64()"/>
									</v-col>

									<v-col cols="12" sm="12" md="12">
										<v-text-field
											v-model="uploadGroupName"
											label="Group Name">
										</v-text-field>
									</v-col>
										</v-form>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="closeUpload()">
								Cancel 
							</v-btn>
							<v-btn
								color="primary"
								text
								@click="
									batchUpload();
									closeUpload();">
									Upload
							</v-btn>
						</v-card-actions>
						
					</v-card>
					
				</v-dialog>

				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title class="text-h5"
							>Are you sure you want to delete this item?
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete()">Cancel</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm()">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
			<v-text-field
				class="pa-5"
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details>
			</v-text-field>

		</template>

		<!-- eslint-disable-next-line -->
		<template v-slot:item.actions="{ item }">
			<!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
			<v-icon small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
			<!-- <v-icon small class="mr-2" @click="print()"> mdi-printer </v-icon>
			<v-icon small class="mr-2" @click="deleteItem(item)"> mdi-mail </v-icon> -->
		</template>

		<template v-slot:no-data>
			<v-btn color="primary" @click="initialize()"> Reset </v-btn>
		</template>

		<!-- <template v-slot:item.discounts="{ item }">
			<v-chip v-for="(key, i) in item.discounts" :key="i" color="black" dark>
				{{ item.discounts[i].brand }} {{ item.discounts[i].percentage + "%" }}
			</v-chip>
		</template> -->
	</v-data-table>
</template>

<script>
import axios from "axios";


export default {
  data: () => ({
    affiliateStatus: {
      calzedonia: false,
      calzedoniaPercentage: 0,

      tezenis: false,
      tezenisPercentage: 0,

      signorvino: false,
      signorvinoPercentage: 0,

      ateliereme: false,
      atelieremePercentage: 0,

      intimissimi: false,
      intimissimiPercentage: 0,

      falconeri: false,
      falconeriPercentage: 0,
    },
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
		errorMessage: "",
		type: "",
    search: "",
    files: [],
    reader: "",
    errorDialog: false,
    uploadGroupName: "",
    /**
     * Headers
     * @displayName Soldo 33
     */
    headers: [
      {
        text: "Shoppy Id",
        align: "start",
        //   sortable: false,
        value: "id",
      },
      { text: "Barcode", value: "barcode" },
      { text: "Status", value: "status" },
      { text: "Group Name", value: "name" },
      { text: 'CAL(%)', value: 'discounts.Calzedonia' },
      { text: 'TEZ(%)', value: 'discounts.Tezenis' },
      { text: 'SIG(%)', value: 'discounts.Signorvino' },
      { text: 'ATE(%)', value: 'discounts.AtelierEme' },
      { text: 'INT(%)', value: 'discounts.Intimissimi' },
      { text: 'FAL(%)', value: 'discounts.Falconeri' },
      { text: "Actions", value: "actions", sortable: false },
    ],
    discountAffiliates: ["Calzedonia", "Tezenis", "SignorVino"],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      barcode: "",
      status: "",
      name: "",
      discounts: [],
    },
    defaultItem: {
      id: "",
      barcode: "",
      status: "",
      name: "",
      discounts: [],
    },
    rules: {
        required: value => !!value || 'Required.',
        min: v => v.length > 0 || 'Min 1 character',
        check: v => v !== '' || 'Select one'
      },
    shoppyIdValid: false,
    barcodeValid: false,
    statusValid: false,
    groupNameValid: false,
    brandsArray: [],
    loaded: true
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Card" : "Edit Card";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogUpload(val) {
      val || this.closeUpload();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
      ["editedItem.id"]: function (name) {
          if (name !== '') {
                if(name.length > 0 && name != undefined){
                  this.shoppyIdValid = true;
                }
                else{
                  this.shoppyIdValid = false;
                }
          } else if (name === '' && name != undefined) {
              this.shoppyIdValid = false;
          }
      },
      ["editedItem.barcode"]: function (name) {
          if (name !== '') {
                if(name.length > 0 && name != undefined){
                  this.barcodeValid = true;
                }
                else{
                  this.barcodeValid = false;
                }
          } else if (name === '' && name != undefined) {
              this.barcodeValid = false;
          }
      },
      ["editedItem.name"]: function (name) {
          if (name !== '' && name != undefined) {
                if(name.length > 0){
                  this.groupNameValid = true;
                }
                else{
                  this.groupNameValid = false;
                }
          } else if (name === '' && name != undefined) {
              this.groupNameValid = false;
          }
      },
  },

  created() {
    this.initialize();
    this.getCards();
    this.addBrandsToArray();
  },

  methods: {
      filter: function(evt) {
			evt = (evt) ? evt : window.event;
			let expect = evt.target.value.toString() + evt.key.toString();
			
			if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) 
			{
				evt.preventDefault();
			}
			else {
				return true;
			}
		},
		resetValue(id,val){
			switch (id) {
				case 0:
					if(val > 100){
						this.affiliateStatus.calzedoniaPercentage = 100;
					}
					else if (val == "" || val <= 0){
						this.affiliateStatus.calzedoniaPercentage = 1;
					}
					break;
				case 1:
				if(val > 100){
						this.affiliateStatus.tezenisPercentage = 100;
					}
					else if (val == "" || val <= 0){
						this.affiliateStatus.tezenisPercentage = 1;
					}
				break;
				case 2:
				if(val > 100){
						this.affiliateStatus.signorvinoPercentage = 100;
					}
					else if (val == "" || val <= 0){
						this.affiliateStatus.signorvinoPercentage = 1;
					}
				break;
				case 3:
				if(val > 100){
						this.affiliateStatus.atelieremePercentage = 100;
					}
					else if (val == "" || val <= 0){
						this.affiliateStatus.atelieremePercentage = 1;
					}
				break;
				case 4:
				if(val > 100){
						this.affiliateStatus.intimissimiPercentage = 100;
					}
					else if (val == "" || val <= 0){
						this.affiliateStatus.intimissimiPercentage = 1;
					}
				break;
				case 5:
				if(val > 100){
						this.affiliateStatus.falconeriPercentage = 100;
					}
					else if (val == "" || val <= 0){
						this.affiliateStatus.falconeriPercentage = 1;
					}
				break;
			
				default:
					break;
			}
			
		},
    getBase64() {
      this.files = this.$refs.myFiles.files;
      console.log(this.files);
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.files[0]);
        reader.onload = () => {
          resolve(reader.result);
          this.reader = reader.result.split(";")[1].split(",")[1];
        };
        reader.onerror = (error) => reject(error);
        console.log(reader);
      });
    },
    async batchUpload() {
      axios
        .post(
          "https://digitalcards.calzedonia.com/graphql",
          {
            query: `mutation{
            bulkCreateCards(bulkCardImportInput:{name: "${this.uploadGroupName}", file: "${this.reader}", clearDb: false}){
              timeElapsed
              count
              cards{
                id
                barcode
                status
                name
                discounts{
                  brand
                  percentage
                }
              }
            }
          }`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
					if(response.data.errors == undefined) {
						const cards = response.data.data.bulkCreateCards ? response.data.data.bulkCreateCards.cards : [];
						cards.map(card => this.formatForDisplay(card));
						console.log(response.data.data.bulkCreateCards.cards);
						this.$store.state.cards = (this.$store.state.cards || []).concat(cards);
						this.loaded = false;
					}

					else{
						console.log(response.data.errors, 'errors');
						this.errorMessage = response.data.errors[0].message;
						this.type = "error"
						this.errorDialog = true;
						this.loaded = false;
					}
					this.$refs.form.reset();
					this.$refs.myFiles.value = null;
				});
    },
    async getCards() {
      this.$store.state.cards = [];
      axios
        .post(
          "https://digitalcards.calzedonia.com/graphql",
          {
            query: `query{
              cards{
                id
                barcode
                status
                name
                discounts{
                  brand
                  percentage
                }
              }
          }`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
          const cards = response.data.data.cards;
          cards.map(card => this.formatForDisplay(card));
          this.$store.state.cards = cards;
          this.loaded = false;
        });
    },
    async deleteCard(index) {
      axios
        .post(
          "https://digitalcards.calzedonia.com/graphql",
          {
            query: `mutation{
            deleteCard(id: "${this.editedItem.id}"){
              id
            }
          }`,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
					if(response.data.errors == undefined){
						console.log(response.data.data.deleteCard);
						this.$store.state.cards.splice(index, 1);
					}else {
						console.log(response.data.errors, "errors");
						this.errorMessage = response.data.errors[0].message;
						this.type = "error";
						this.errorDialog = true;
						this.loaded = false;
					}
				});
    },
    async createCard() {
      this.addBrandsToArray();
      const el = (element) => element === true;
      if(this.brandsArray.some(el) && this.shoppyIdValid
      && this.barcodeValid && this.groupNameValid){
        console.log(el);
      if (this.affiliateStatus.calzedonia) {
        this.editedItem.discounts.push({
          brand: "Calzedonia",
          percentage: +this.affiliateStatus.calzedoniaPercentage,
        });
        console.log(this.editedItem.discounts);
      }
      if (this.affiliateStatus.tezenis) {
        this.editedItem.discounts.push({
          brand: "Tezenis",
          percentage: +this.affiliateStatus.tezenisPercentage,
        });
        console.log(this.editedItem.discounts);
      }
      if (this.affiliateStatus.signorvino) {
        this.editedItem.discounts.push({
          brand: "Signorvino",
          percentage: +this.affiliateStatus.signorvinoPercentage,
        });
        console.log(this.editedItem.discounts);
      }
      if (this.affiliateStatus.ateliereme) {
        this.editedItem.discounts.push({
          brand: "Atelier Eme",
          percentage: +this.affiliateStatus.atelieremePercentage,
        });
        console.log(this.editedItem.discounts);
      }
      if (this.affiliateStatus.intimissimi) {
        this.editedItem.discounts.push({
          brand: "Intimissimi",
          percentage: +this.affiliateStatus.intimissimiPercentage,
        });
        console.log(this.editedItem.discounts);
      }
      if (this.affiliateStatus.falconeri) {
        this.editedItem.discounts.push({
          brand: "Falconeri",
          percentage: +this.affiliateStatus.falconeriPercentage,
        });
        console.log(this.editedItem.discounts);
      }

      var test = `mutation{
            createCard(cardInput: 
              {id: "${this.editedItem.id}", 
                barcode: "${this.editedItem.barcode}", 
                status: "New", 
                name: "${this.editedItem.name}", 
                discounts: ${this.cleanIt(this.editedItem.discounts)}
              }){
              id
              barcode
              status
              name
              discounts{
                brand
                percentage
              }
            }
          }`;
      console.log(test);
      axios
        .post(
          "https://digitalcards.calzedonia.com/graphql",
          {
            query: test,
          },
          {
            headers: {
              Authorization: "Bearer " + (await this.$auth.getAccessToken()),
            },
          }
        )
        .then((response) => {
					if(response.data.errors == undefined){
						const card = response.data.data.createCard;
						const formattedCard = this.formatForDisplay(card);
						this.$store.state.cards.push(formattedCard);
						this.loaded = false;
					}
					else{
						console.log(response.data.errors, 'errors');
						this.errorMessage = response.data.errors[0].message;
						this.type = "error";
						this.errorDialog = true;
						this.loaded = false;
					}
				});
        this.close();
      }
    },
    formatForDisplay(card) {
          const possibleBrands = ['Calzedonia', 'Intimissimi', 'Tezenis', 'Falconeri', 'AtelierEme', 'Signorvino'];
          const formattedDiscounts = {};
          possibleBrands.forEach(brand => {
            const discountIdx = card.discounts.findIndex(discount => discount.brand.trim() === brand);
            formattedDiscounts[brand.trim()] = discountIdx !== -1 ? card.discounts[discountIdx].percentage : 0;
          });
          card.discounts = formattedDiscounts;
          return card;
    },
    cleanIt(obj) {
      var cleaned = JSON.stringify(obj, null, 2);

      return cleaned.replace(/^[\t ]*"[^:\n\r]+(?<!\\)":/gm, function(match) {
        return match.replace(/"/g, "");
      });
    },

    initialize() {
      // this.$store.state.cards.push({
      //     id: "222000000017",
      //     status: "New",
      //     name: "Cal - 20%",
      //     discounts:[{
      //       brand: "Calzedonia",
      //       percentage: 20,
      //     },
      //     {
      //       brand: 'Tezenis',
      //       percentage: 30,
      //     }],
      //   });
      // console.log(this.rules.required());
    },
    print() {
      window.print();
    },

    editItem(item) {
      this.editedIndex = this.$store.state.cards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.$store.state.cards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteCard(this.editedIndex);

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.discounts = [];
        this.editedIndex = -1;
      });
        this.affiliateStatus.calzedonia = false;
        this.affiliateStatus.tezenis = false;
        this.affiliateStatus.signorvino = false;
        this.affiliateStatus.ateliereme = false;
        this.affiliateStatus.intimissimi = false;
        this.affiliateStatus.falconeri = false;

        this.affiliateStatus.calzedoniaPercentage = 0;
        this.affiliateStatus.tezenisPercentage = 0;
        this.affiliateStatus.signorvinoPercentage = 0;
        this.affiliateStatus.atelieremePercentage = 0;
        this.affiliateStatus.intimissimiPercentage = 0;
        this.affiliateStatus.falconeriPercentage = 0;
        if(this.$refs.form.resetValidation() !== undefined){
        this.$refs.form.resetValidation();
      }
        
    },
    closeUpload() {
      this.dialogUpload = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addBrandsToArray(){
      this.brandsArray = [];
      this.brandsArray.push(
        this.affiliateStatus.calzedonia,
        this.affiliateStatus.tezenis,
        this.affiliateStatus.signorvino,
        this.affiliateStatus.ateliereme,
        this.affiliateStatus.intimissimi,
        this.affiliateStatus.falconeri);
    },

    save() {
      
        if (this.editedIndex > -1) {
          Object.assign(
            this.$store.state.cards[this.editedIndex],
            this.editedItem
          );
        } else {
          this.$store.state.cards.push(this.editedItem);
        }
        if(this.$refs.form.resetValidation() !== undefined){
        this.$refs.form.resetValidation();
      }
        this.close();
      
    },
  },
};
</script>

<style scoped>
.border {
	border: 1px solid #007bff;
}
input {
	color: #007bff;
}
</style>
