<template>
  <v-container id="tableContainer">
    <v-row class="top-offset" no-gutters>
      <!-- <v-col cols="12">
        <AvatarInfo :level="level" :avatarValue="avatarValue" />
      </v-col> -->

      <v-col cols="12" lg="12" md="12">
        <CardsManagmentDataTable />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CardsManagmentDataTable from "../components/CardsManagmentDataTable.vue";
// import AvatarInfo from "../components/AvatarInfo.vue";
export default {
  components: {
    CardsManagmentDataTable,
    // AvatarInfo,
  },
  data() {
    return {
      level: 1,
      avatarValue: 1
    }
  }
};
</script>

<style scoped>
.top-offset {
  margin-top: 100px !important;
}
</style>
